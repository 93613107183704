.videoSlider
{
    position: fixed;
    left: 0;
    top: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1060;
    width: 100%;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.7);

    &_inner
    {
        width: 920px;
        max-height: 100vh;
        border-radius: 10px;
        position: relative;
        display: flex;
        justify-content: space-between;
    }

    &_left
    {
        background: #0E090D;
        color: white;
        border-radius: 5px;
        padding: 10px;
        border: 1px solid #1a1a1a;
        width: 220px;
        margin-right: 5px;
        flex-shrink: 0;
    }

    &_right
    {
        width: 100%;
    }

    &_close
    {
        position: absolute;
        right: -20px;
        top: -5px;
        font-size: 22px;
        cursor: pointer;
        color: white;
        z-index: 1;
    }

    &_top
    {
        border-bottom: 1px solid #2b2b2b;
        padding-bottom: 10px;

        img
        {
            width: 150px;
        }
    }

    &_title
    {
        font-family: 'raven-bold-inline';
        font-size: 18px;
        margin-top: 10px;
        color: #9e7d49;
    }

    &_item
    {
        border: 1px solid #1d1b18;
        border-radius: 5px;
        padding: 0px 5px 5px 5px;
        cursor: pointer;
        margin-top: 5px;
    }

    &_item.active
    {        
        border: 1px solid #9e7d49;
    }

    &_duration 
    {
        background: #9e7d49;
        border-radius: 2px;
        font-size: 10px;
        padding: 0 5px;
    }

    &_videoName
    {
        display: flex;
        align-items: center;
        margin-top: 5px;

        img
        {
            width: 12px;
            margin-right: 10px;
        }

        p
        {
            font-size: 12px;
            font-family: 'raven-bold-inline';
        }
    }

    &_list
    {
        height: 270px;
        overflow: auto;
        padding-right: 5px;
    }

    &_list::-webkit-scrollbar-thumb
    {
        background: #FFF3AD;
        border-radius: 20px;
    }

    &_list::-webkit-scrollbar
    {
        width: 8px;
    }

    &_chapter 
    {
        font-family: sans-serif;
        margin: 8px 0;
        font-weight: 600;
    }

    .youtube-container
    {
        height: 100%;
    }

    .youtube-container > div {
        height: 100%;
    }
    
    .youtube-container > div iframe {
        width: 100%;
        height: 100%;
    }

    &_text
    {
        padding: 10px;
        margin-bottom: 0;
    }

    &_text img
    {
        width: 20px;
        display: inline-block;
    }

    &_next
    {
        display: flex;
        justify-content: flex-end;
        margin-bottom: 10px;
        font-family: segoe ui;
        font-size: 20px;
        font-weight: 500;
    }

    &_info
    {
        display: flex;
        align-items: center;
        padding: 10px 10px 0;
        font-family: segoe ui;

        img
        {
            cursor: pointer;
            width: 100px;
        }

        p
        {
            margin-bottom: 0;
            font-weight: 600;
            font-size: 15px;

            svg
            {
                display: inline-block;
            }

            img
            {
                width: 150px;
                display: inline-block;
            }
        }
    }

    &_imgBg
    {
        width: 100% !important;
    }

    &_playIcon {
        width: 30px;
        position: absolute;
        top: 273px;
        right: 187px;
        cursor: pointer;
    }

    &_actionBtn {
        color: white;
        position: absolute;
        right: 60px;
        bottom: -50px;
        cursor: pointer;
        font-weight: 600;
        border: 1px solid white;
        padding: 8px 12px;
        font-size: 12px;
        border-radius: 5px;
        font-family: sans-serif;
    }

    .arrow-right {
		color: white;
		font-size: 40px;
		position: absolute;
		top: 50%;
		right: -50px;
		border: 2px solid #a87833;
		border-radius: 100%;
		padding: 10px;
		cursor: pointer;
	}

	.arrow-left {
		color: white;
		font-size: 40px;
		position: absolute;
		top: 50%;
		left: -50px;
		border: 2px solid #a87833;
		border-radius: 100%;
		padding: 10px;
		cursor: pointer;
	}

    .arrow-down {
        font-size: 18px;
        color: #a87833;
        display: inline-block;
    }

    &_description {
        font-family: segoe ui;
        font-size: 11px;
        line-height: 14px;
        margin-top: 5px;
    }

    &_infoText {
        color: white;
        position: absolute;
        bottom: -55px;
        font-size: 14px;
        font-family: sans-serif;
        width: 600px;
        text-align: center;

        button
        {
            background: -webkit-linear-gradient(right,
            #e9dd9c,
            #a87833,
            hsl(37, 37%, 45%),
            #c9a465,
            #ab915d);
            border-radius: 50px;
            cursor: pointer;
            padding: 5px 20px;
            color: white;
            font-family: raven-bold-inline !important;
            font-size: 12px;
            margin: 0px;
        }
    }
}